import { createRouter, createWebHistory } from 'vue-router'
import lazyLoading from '@/router/lazy-loading'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: lazyLoading('Home'),
  },
  {
    path: '/:galleryId',
    name: 'Gallery',
    component: lazyLoading('Gallery'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

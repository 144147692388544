<template>

	<div class="text-center mb-4 mt-8">
		<div class="flex justify-center">
			<a href="https://www.facebook.com/alstoneart" target="_blank">
				<img class="w-14 h-14" src="@/assets/facebook.svg" />
			</a>
			<a href="https://www.instagram.com/alicestoneart" target="_blank">
				<img class="w-14 h-14" src="@/assets/instagram.svg" />
			</a>
			<a href="https://www.pinterest.co.uk/alicestoneart" target="_blank">
				<img class="w-14 h-14" src="@/assets/pintrest.svg" />
			</a>
			<a href="mailto:shotsbystone@gmail.com" target="_blank">
				<img class="w-14 h-14" src="@/assets/mail.svg" />
			</a>
		</div>
		<a href="https://alicestone.art">alicestone.art</a>
	</div>

</template>

<script>
export default {

	name: 'Footer',

}
</script>

<style lang="css" scoped>
	svg {
		fill: #9A8462;
	}
	svg:hover {
		fill:  #424242;
	}
	a {
		color: #9A8462 !important;
	}
</style>
<template>

  <div>

    <div class="min-h-screen bg-alice-100 pb-20" :style="{ background: backgroundColor }">

      <header class="bg-white shadow">
          <div class="grid max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 place-items-center">
              <img src="@/assets/logo.svg" class="h-20 w-auto"/>
          </div>
      </header>

      <main>

        <div class="container max-w-7xl mx-auto sm:px-6 lg:px-8 mt-4">

          <router-view />

        </div>
        
      </main>

      <footer>
        <FooterComponent />
      </footer>

    </div>

  </div>

</template>

<script>
import FooterComponent from '@/components/Footer'

export default {

  name: 'App',

  components: {
    FooterComponent,
  },

  data() {

    return {
      backgroundColor: null,
    }
  },

  methods: {

    showLoadingScreen() {

      this.$isLoading(true)
      setTimeout(() => {
        this.$isLoading(false)
      }, 2000)

    }
  },

  mounted() {

    this.showLoadingScreen()
  },

  created() {
    this.emitter.on('background-change', (evt) => {
      this.backgroundColor = evt.background
    })
  },
}
</script>

<style lang="css">
  
</style>
import { createApp } from 'vue'
import VueImgix from '@imgix/vue'
import App from './App.vue'
import Loading from 'vue3-loading-screen'
import VueEasyLightbox from 'vue-easy-lightbox'
import Logo from '@/config/loading.js'
import router from './router'
import mitt from 'mitt'
import './styles/app.css'

const emitter = mitt()

const app = createApp(App)
	.use(router)
	.use(Loading, Logo)
	.use(VueEasyLightbox)
	.use(VueImgix, {
		domain: process.env.VUE_APP_IMGIX_DOMAIN,
		defaultIxParams: {
			// This enables the auto format imgix parameter by default for all images, which we recommend to reduce image size, but you might choose to turn this off.
			auto: 'format',
			lossless: true,
			ch: 'Width,Viewport-Width,DPR,Save-Data',
		},
	})

app.config.globalProperties.emitter = emitter

app.mount('#app')